import * as React from 'react'
import AinoCheckout from 'aino-checkout'
import classnames from 'classnames'
import isNode from 'is-node'
import { inject, observer } from 'mobx-react'
import { navigate } from 'gatsby'

import 'src/styles/checkout.scss'
import Analytics from 'src/utils/analytics'
import Cart from 'src/stores/cart'
import CheckoutError from 'src/components/CheckoutError/CheckoutError'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'
// import Report from 'src/utils/report'
import SEO from 'src/components/SEO/SEO'
import config from 'src/config'
import globalMessages from 'src/globalMessages'
import { BasketIcon } from 'src/components/UI/Icon/Icon'

import styles from './checkout.module.scss'

import logotype from 'src/assets/images/hob-logo.svg'

import '../../node_modules/aino-checkout/lib/checkout.css'

type Props = {
  cart?: Cart
  pageContext: {
    canonical: string
    locale: any
  }
}

type State = {
  emptyCart: boolean
  error: string
}

@inject('cart')
@observer
class Checkout extends React.Component<Props> {
  timer: any
  api: any

  state: State = {
    emptyCart: false,
    error: '',
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render(): JSX.Element {
    const { pageContext, cart } = this.props
    const { emptyCart } = this.state
    const { canonical, locale } = pageContext

    const seoData = {
      bodyClass: 'aino-route-checkout',
      canonical,
      lang: locale,
      noIndex: true,
      title: globalMessages.checkout.defaultMessage,
    }

    const centraCheckout = config.checkout.apiUrl
  
    /* eslint-disable */
    // NB! DO NOT CHANGE THESE KEYS!!!
    // They are keys, not messages. Messages can be found in src/checkoutMessages.ts

    /* eslint-enable */
    const checkoutConfig = {
      token: cart!.token,
      apiUrl: centraCheckout,

      renderLogo: () => (
        <img src={logotype} alt="House Of Bradford Logotype" width="100%" />
      ),
      onReceipt: (order: any) => {
        console.log('Order in checkout.tsx', order) /* eslint-disable-line */
        const receipt = '/receipt'
        navigate(receipt, { state: { order: Object.assign({}, order) } })
        cart!.refresh()
      },
      onTermsClick: () => {
        const terms = '/allmanna-villkor'
        window.open(terms)
      },
      onReturnClick: () => {
        const home = '/'
        cart!.refresh()
        navigate(home)
      },
      onEmptyCart: () => {
        this.setState({
          emptyCart: true,
        })
      },
      onError: (err: Error) => {
        //Todo send this to sentry
        console.log(err)

        this.setState({
          error: err.message,
        })
        // Report.error(err)
      },
      onWarning: (err: Error) => {
        console.log('error:', err)
        // Report.warn(err)
      },
      onApiReady: (api: any) => {
        this.api = api
      },
      onCheckoutStateView: ({ state, cart }: { state: string; cart?: any }) => {
        if (!cart) {
          return false
        }
        const country = cart!.country

        const currency =
          (cart &&
            cart.cart &&
            cart.cart.selection &&
            cart.cart.selection.currency) ||
          ''
        const items = cart!.items
        switch (state) {
          case 'address':
            const products: any[] = []
            for (const product of items) {
              let productCategory =
                (product.product &&
                  product.product['google_merchant_category']) ||
                ''

              if (!productCategory) productCategory = ''
              products.push({
                category: productCategory,
                id: product.sku,
                name: product.product.name,
                price: product.priceEachWithoutTaxAsNumber.toString(),
                quantity: product.quantity,
                sku: product.sku,
                variant: product.size,
              })
            }
            Analytics.track('checkout', {
              actionField: { step: 1 },
              country,
              currency,
              products,
            })
            break
          case 'delivery':
            Analytics.track('checkout', {
              actionField: { step: 2 },
              country,
              currency,
            })
            break
          case 'payment':
            Analytics.track('checkout', {
              actionField: { step: cart!.oneShipping ? 2 : 3 },
              country,
              currency,
            })
            break
        }
      },
    }

    if (isNode) {
      return (
        <section>
          <SEO {...seoData} />
          <div className={classnames(styles.nojs, 'hide-js')}>
            <PrimaryButton onClick={() => navigate('/')}>
              Back to store
            </PrimaryButton>
          </div>
        </section>
      )
    }

    return (
      <div>
        <SEO {...seoData} />
        {this.state.error ? (
          <CheckoutError
            message="Something went wrong."
            error={this.state.error}
          />
        ) : null}

        {!isNode && !emptyCart && cart!.token && cart!.count ? (
          <AinoCheckout config={checkoutConfig} />
        ) : null}

        {emptyCart || (cart!.token && !cart!.count) ? (
          <div className="aino-contentWrapper">
            <div className="aino-emptyCartWrapper">
              <BasketIcon width={60} height={60} color="black" />
              <h1 style={{ margin: '20px 0 40px' }}>Your bag is empty.</h1>
              <a href="/" style={{ minWidth: '220px' }}>
                <PrimaryButton black>Continue shopping</PrimaryButton>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Checkout
