import * as React from 'react'
import isNode from 'is-node'
import posed from 'react-pose'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'

import styles from './CheckoutError.module.scss'

type Props = {
  error: string
  message: string
}

const Shade = posed.div({
  init: { opacity: 0 },
  active: {
    opacity: 1,
  },
})

const Modal = posed.div({
  init: { opacity: 0, y: 35 },
  active: {
    delay: 200,
    opacity: 1,
    y: 0,
  },
})

const CheckoutError = ({ message, error }: Props) => (
  <React.Fragment>
    <Shade pose="active" initialPose="init" className={styles.overlay} />
    <Modal pose="active" initialPose="init" className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <span>{message}</span>
          {error && <span className={styles.error}>{error}</span>}

          <PrimaryButton
            onClick={() => {
              if (!isNode && document) {
                document.location.reload(true)
              }
            }}
          >
            Reload page
          </PrimaryButton>
          <LinkComponent to="/">Return to shop</LinkComponent>
        </div>
      </div>
    </Modal>
  </React.Fragment>
)

export default CheckoutError
