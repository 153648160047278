const globalMessages = {
  receipt: {
    id: 'Global.receipt',
    defaultMessage: 'Receipt',
  },
  thankYou: {
    id: 'Global.thankYou',
    defaultMessage: 'Thank You',
  },
  successfullySubmitted: {
    id: 'Global.successfullySubmitted!',
    defaultMessage: 'Your order was successfully submitted!',
  },
  referenceIdForThisOrder: {
    id: 'Global.referenceIdForThisOrder',
    defaultMessage: 'Reference ID for this order: ',
  },
  youWillShortlyBeContacted: {
    id: 'Global.youWillShortlyBeContacted',
    defaultMessage:
      'You will shortly receive a confirmation email to the address you provided.',
  },
  forQuestions: {
    id: 'Global.forQuestions',
    defaultMessage: 'For questions please see our',
  },
  notFound: {
    id: 'Global.notFound',
    defaultMessage: 'Not Found',
  },
  couldNotFindReceipt: {
    id: 'Global.couldNotFindReceipt',
    defaultMessage: 'Could not find your receipt.',
  },
  fullSiteName: {
    id: 'Global.fullSiteName',
    defaultMessage: 'Brandmate',
  },
  checkout: {
    id: 'Global.checkout',
    defaultMessage: 'Checkout',
  },
  cookieMessage: {
    id: 'Global.cookieMessage',
    defaultMessage:
      'We use cookies to improve your experience on our site and for marketing purposes. To find out more, read our updated <a>cookie policy</a>',
  },
  ok: {
    id: 'Global.ok',
    defaultMessage: 'OK',
  },
  overlay: {
    id: 'Global.overlay',
    defaultMessage: 'Overlay',
  },
  search: {
    id: 'Global.search',
    defaultMessage: 'Search',
  },
  close: {
    id: 'Global.close',
    defaultMessage: 'Close',
  },
  sold: {
    id: 'Global.sold',
    defaultMessage: 'Sold',
  },
  size: {
    id: 'Global.size',
    defaultMessage: 'Size',
  },
  campaign: {
    id: 'Global.campaign',
    defaultMessage: 'Campaign',
  },
}

export default globalMessages
