import isNode from 'is-node'

import { camelCase } from 'src/utils/misc'

export type TrackableItem = {
  currency: string
  item?: string
  name: string
  price?: number | string
  quantity?: number
  size?: string
  sku: string
}

const sizeLookup: {
  [key: string]: string
} = {
  'X Small': '000',
  Small: '001',
  Medium: '002',
  Large: '003',
  'X Large': '004',
  'XX Large': '005',

  '6 months': 'K01',
  '1 year': 'K05',
  '2 years': 'K06',
  '3 years': 'K07',
  '4 years': 'K08',
  '6 years': 'K10',
  '8 years': 'K12',
  '10 years': 'K14',

  'One Size': '998',
}

const createSizeSKU = (sku: string, size: string) => {
  const sizeSKU = `${sku}`

  if (sizeSKU === 'undefined' || sizeSKU.includes('-')) return sku
  if (sizeSKU && !size) return sizeSKU
  if (sizeSKU.length === 6) {
    // Pants
    const wlMatch = `${size}`.match(/(W\d{2})\/(L\d{2})/)
    if (wlMatch) {
      return `${sizeSKU}-${wlMatch[2]}-${wlMatch[1]}`
    }
    return sizeSKU
  } else if (sizeSKU.length === 9) {
    // Other
    const parts = [sizeSKU.slice(0, 6), sizeSKU.slice(6)]
    let sizePart
    const cmMatch = size.match(/(\d{2,3}) cm$/)
    if (cmMatch) {
      sizePart = cmMatch[1]
      sizePart = sizePart.length === 2 ? `0${sizePart}` : sizePart
      parts.push(sizePart)
    } else if (sizeLookup.hasOwnProperty(size)) {
      parts.push(sizeLookup[size])
    }
    return parts.join('-')
  } else {
    return sku
  }
}

const normalizeProduct = (product: any) => {
  const normalizedProduct: any = {
    brand: 'Brandmate',
    id: product.sku,
    name: product.name || product.productName,
  }
  if (product.category) {
    normalizedProduct.category = product.category
  } else if (product.id) {
    // const category = Utils.getProductCategory(product.id)
    // if (category) {
    //   normalizedProduct.category = category
    // }
  }
  if (product.price) normalizedProduct.price = product.price
  if (product.quantity) normalizedProduct.quantity = product.quantity
  if (product.size) normalizedProduct.variant = product.size
  if (product.list) normalizedProduct.list = product.list
  if (product.position) normalizedProduct.position = product.position

  return normalizedProduct
}

const Analytics = {
  track(event: any, payload: any) {
    console.log('Tracking', event, payload) /* eslint-disable-line */
    if (!isNode && window.dataLayer) {
      const layerData: {
        countryCode?: string
        ecommerce?: any
        event: string
        payload?: any
      } = {
        // Camelcase just in case. All event names should be changed.
        event: camelCase(event),
      }

      switch (event) {
        case 'addToCart':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            add: {
              products: [normalizeProduct(payload)],
            },
          }
          if (payload.source) {
            layerData.ecommerce.add.actionField = {
              source: payload.source,
            }
          }
          break

        case 'removeFromCart':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            remove: {
              products: [normalizeProduct(payload)],
            },
          }
          if (payload.source) {
            layerData.ecommerce.remove.actionField = {
              source: payload.source,
            }
          }
          break

        case 'viewedProduct':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            detail: {
              products: [normalizeProduct(payload)],
            },
          }
          break

        case 'productClick':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            click: {
              products: [normalizeProduct(payload)],
            },
          }
          if (payload.source) {
            layerData.ecommerce.click.actionField = {
              list: payload.source,
            }
          }
          break

        case 'impressions':
          layerData.ecommerce = {
            impressions: payload.map(normalizeProduct),
          }
          break

        case 'checkout':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            checkout: {
              actionField: payload.actionField,
            },
          }
          if (payload.country) {
            layerData.ecommerce.countryCode = payload.country
          }
          if (payload.products) {
            layerData.ecommerce.checkout.products = payload.products.map(
              normalizeProduct
            )
          }
          break

        case 'purchase':
          layerData.ecommerce = {
            currencyCode: payload.currency,
            purchase: payload.purchase,
          }
          break

        case 'userInfo':
          layerData.countryCode = payload.countryCode
          break

        default:
          layerData.payload = payload
      }

      window.dataLayer.push(layerData)

      if (payload.sku) {
        /* eslint-disable */
        window.dataLayer.push({
          event: 'fireRemarketingTag',
          google_tag_params: {
            ecomm_pagetype: 'product',
            ecomm_prodid: createSizeSKU(payload.sku, payload.size),
            ecomm_totalvalue: payload.price,
          },
        })
        /* eslint-enable */
      }
    }
  },

  page(pageType: string, slug: string) {
    if (!isNode && window.dataLayer) {
      window.dataLayer.push({
        event: 'virtualPageview',
        pageType,
        slug,
      })
    }
  },
}

export default Analytics
